<template>

  <section class="forms">
    <div class="page-header">
      <h3 class="page-title">
        Edit Plan {{ $route.params.id }}
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Plans</a></li>
          <li class="breadcrumb-item active" aria-current="page">Edit</li>
        </ol>
      </nav>
    </div>
    <div class="row">
    <div class="col-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <b-tabs class="tab-solid tab-solid-primary">
            <b-tab active>
              <template slot="title">
                <i class='mdi mdi-buffer'></i> Summary
              </template>
              <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
                <form @submit.prevent="handleSubmit(onSubmit)" class="forms-sample">
                 <div class="row">
                  <div class="col-md-6 grid-margin stretch-card">
                   <div class="card">
                    <div class="card-body">
                      <ValidationProvider name="planNo" rules="required">
                        <b-form-group slot-scope="{ errors }" label="Plan No"  label-for="planNo">
                          <b-form-input type="text" id="planNo" v-model="plan.planNo" placeholder="Plan No"></b-form-input>
                            <p>{{ errors[0] }}</p>
                          </b-form-group>
                      </ValidationProvider>
                     <ValidationProvider name="district" rules="required">
                       <b-form-group slot-scope="{ errors }" label="District"  label-for="district">
                         <b-form-input type="text" id="district" v-model="plan.district" placeholder="District"></b-form-input>
                         <p>{{ errors[0] }}</p>
                      </b-form-group>
                   </ValidationProvider>
                  <ValidationProvider name="city" rules="required">
                    <b-form-group slot-scope="{ errors }" label-for="city" horizontal label="City">
                      <b-form-select id="city" v-model="city_id" :options="cities"/>
                      <p>{{ errors[0] }}</p>
                    </b-form-group>
                  </ValidationProvider>
                </div>
              </div>
            </div>

           <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
             <div class="card-body">
              <h4 class="card-title">Points</h4>
              <p class="card-description">
               Add longitude & latitude of points and click the add button to add until it's 3.
             </p>
             <ValidationProvider name="longitude">
              <b-form-group label="Longitude" label-for="longitude">
                <b-form-input type="text" :disabled="points.length > 2" id="longitude" v-model="longitude" placeholder="Longitude"></b-form-input>
              </b-form-group>
              </ValidationProvider>
                <ValidationProvider name="latitude">
                 <b-form-group label="Latitude" label-for="longitude">
                   <b-form-input type="text" :isabled="points.length > 2" id="latitude" v-model="latitude" placeholder="Latitude"></b-form-input>
                 </b-form-group>
               </ValidationProvider>
               <b-form-group class="float-right">
                <b-button type="button" :disabled="!addCheck" @click="addPoint" variant="success">Add Point</b-button>
               </b-form-group>
               <b-table striped hover :items="points">
                 <template v-slot:cell(action)="data">
                  <b-button variant="danger" @click="removePoint(data.value.action)">remove</b-button>
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <b-form-group class="float-right">
                <b-button type="submit" variant="success" :disabled="invalid" class="mr-2">{{ create_text }}</b-button>
                <b-button type="button" @click="resetForm" variant="light">Reset</b-button>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </form>
    </ValidationObserver>
    </b-tab>
    <b-tab>
      <template slot="title">
        <i class=" mdi mdi-navigation"></i> Geo JSON
      </template>
      <div class="row">
        <div class="col-12 stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Actions</h4>
              <b-form-group class="">
                <b-button type="button" disabled @click="reprocess" variant="success" class="mr-2">{{ reprocess_text }}</b-button>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
      <form @submit.prevent="handleSubmit(updateGeoJSON)" class="forms-sample">
      <div class="row">
        <div class="col-md-12 stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Geo JSON file</h4>
              <p class="card-description">
                please upload a transactions .geojson file.
              </p>
              <div class="row">
                <div class="col-md-12">
                  <ValidationProvider name="geo_json" rules="required">
                    <b-form-group slot-scope="{ errors }" label="Geo JSON" label-for="geo_json">
                      <b-form-file v-model="file" accept=".geojson" id="geo_json" :state="Boolean(file)" placeholder="Choose a file..."></b-form-file>
                      <p>{{ errors[0] }}</p>
                    </b-form-group>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <b-form-group class="float-right">
                <b-button type="submit" variant="success" :disabled="invalid" class="mr-2">{{ geo_text }}</b-button>
                <b-button type="button" @click="resetGeo" variant="light">Clear</b-button>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      </form>
      </ValidationObserver>
    </b-tab>
    </b-tabs>
    </div>
    </div>
    </div>
    </div>
    <vue-snotify></vue-snotify>
  </section>

</template>

<script lang="js">
/* eslint-disable no-debugger, no-console */

import { ValidationObserver } from 'vee-validate'

export default {
  name: 'planEdit',
  data () {
    return {
      neighborhood: "",
      planNo: "",
      district: "",
      block: "",
      meter_sq: "",
      longitude: "",
      latitude: "",
      create_text: "Update Plan",
      geo_text: "Update GeoJSON",
      reprocess_text: "Reprocess GeoJSON",
      city_id: "",
      points: [],
      plan: {},
      file: null
    }
  },
  components: {
    ValidationObserver
  },
  computed: {
    addCheck() {
      return this.longitude != "" && this.latitude != "" && (this.points.length < 3)
    },
    cities() {
      return this.$store.getters['city/listCities']
    }
  },
  methods: {
    onSubmit() {
      this.create_text = "Updating..."
      let payload = {
        plan: {
          planNo: this.plan.planNo,
          district: this.plan.district,
          points: this.points,
          city_id: this.city_id
        },
        id: this.$route.params.id
      }
      if (this.points.length < 3)
        delete payload.plan.points
        
      this.$store.dispatch('plan/updatePlan', payload)
      .then(() => {
        this.create_text = "Update Plan"
        this.resetForm()
        this.$snotify.success("Plan updated successfully!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .catch(() => {
        this.create_text = "Update Plan"

        this.$snotify.warning("Something went wrong!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
    },
    updateGeoJSON() {
      this.geo_text = "Updating..."
      let form_data = new FormData()

      form_data.append('file', this.file, this.file.name)

      let payload = {
        id: this.$route.params.id,
        form_data: form_data
      }

      this.$store.dispatch('plan/updateGeoJSON', payload)
      .then((res) => {
        this.geo_text = "Update GeoJSON"
        this.resetGeo()
        this.$snotify.success(res.data.message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .catch((error) => {
        this.geo_text = "update GeoJSON"
        let message = "Something went wrong!"
        if(error.message)
          message = error.response.data.message

        this.$snotify.warning(message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
    },
    reprocess() {
      this.reprocess_text = "Reprocessing"

      this.$store.dispatch('plan/reprocess', this.$route.params.id)
      .then((res) => {
        this.reprocess_text = "Reprocess GeoJSON"
        this.$snotify.success(res.data.message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .catch((error) => {
        this.geo_text = "Reprocess GeoJSON"
        let message = "Something went wrong!"
        console.log(error)
        if(error.message)
          message = error.response.data.message

        this.$snotify.warning(message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
    },
    addPoint() {
      this.points.push({lat: this.latitude, lng: this.longitude, action:this.points.length})
      this.latitude = this.longitude = ""
    },
    removePoint(index) {
      this.points.splice(index, 1)
    },
    resetForm() {
      this.plot = ""
      this.$nextTick(() => {
        this.$refs.form.reset()
      })
    },
    resetGeo() {
      this.file = null
      this.$nextTick(() => {
        this.$refs.form.reset();
      })
    },
  },
  created() {
    this.$store.dispatch("plan/fetchPlan", this.$route.params.id)
    .then(res => {
      this.plan = res.data.data

      if(this.plan.city.id)
        this.city_id = this.plan.city.id

      if(this.points)
        this.points = this.points
    })
    const payload = {
      meta: {
        page: 1,
        paginate: 200
      },
    }
    this.$store.dispatch("city/fetchCities", payload)
  }
}
</script>
